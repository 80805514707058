import DashboardLayout from "@/pages/layouts/DashboardLayout";

let teamMembersRoutes = {
  path: '/team-members',
  component: DashboardLayout,
  redirect: '/team-members/list',
  children: [
    {
      path: 'list/en',
      name: 'Authors List',
      component: () => import(/* webpackChunkName: "Team Member" */  '@/pages/teamMembers/TeamMemberList'),
      meta: {
        permissionsCodes: ['en/team-members/index', 'restricted_own/en/team-members/index'],
      }
    },
    {
      path: 'create/en',
      name: 'Add Team Member',
      component: () => import(/* webpackChunkName: "Team Member" */  '@/pages/teamMembers/TeamMemberForm'),
      meta: {
        permissionsCodes: ['en/team-members/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit Team Member',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/teamMembers/TeamMemberForm'),
      meta: {
        permissionsCodes: ['en/team-members/update', 'restricted_own/ar/team-members/update'],
      }
    }
  ]
}

export default teamMembersRoutes;
