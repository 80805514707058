import DashboardLayout from "@/pages/layouts/DashboardLayout";

let mediaInfoRoutes = {
    path: '/mediaInfo',
    component: DashboardLayout,
    redirect: '/mediaInfo/list',
    children: [
      {
        path: 'list/:lang',
        name: 'Media Info List',
        component: () => import(/* webpackChunkName: "mediaInfo" */  '@/pages/general/mediaInfo/MediaInfoList'),
        meta: {
          permissionsCodes: ['ar/mediaInfo/index', 'restricted_own/ar/mediaInfo/index'],
        }
      },
      {
        path: 'create/:lang',
        name: 'Add Media Info Item',
        component: () => import(/* webpackChunkName: "mediaInfo" */  '@/pages/general/mediaInfo/MediaInfoForm'),
        meta: {
          permissionsCodes: ['ar/mediaInfo/create'],
        }
      },
      {
        path: 'edit/:id/:lang',
        name: 'Edit Media Info Item',
        component: () => import(/* webpackChunkName: "mediaInfo" */  '@/pages/general/mediaInfo/MediaInfoForm'),
        meta: {
          permissionsCodes: ['ar/mediaInfo/update', 'restricted_own/ar/mediaInfo/index'],
        }
      }
    ]
  }

  export default mediaInfoRoutes;
