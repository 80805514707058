import DashboardLayout from "@/pages/layouts/DashboardLayout";

let projectsRoutes = {
  path: '/projects',
  component: DashboardLayout,
  redirect: '/projects/list',
  children: [
    {
      path: 'list/en',
      name: 'Projects List',
      component: () => import(/* webpackChunkName: "projects" */  '@/pages/projects/ProjectsList'),
      meta: {
        permissionsCodes: ['en/projects/index', 'restricted_own/en/projects/index'],

      }
    },
    {
      path: 'create/en',
      name: 'Add Project',
      component: () => import(/* webpackChunkName: "projects" */  '@/pages/projects/ProjectsForm'),
      meta: {
        permissionsCodes: ['en/projects/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit Project',
      component: () => import(/* webpackChunkName: "projects" */  '@/pages/projects/ProjectsForm'),
      meta: {
        permissionsCodes: ['en/projects/update', 'restricted_own/en/projects/update'],
      }
    }
  ]
}

export default projectsRoutes;
