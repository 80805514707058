import DashboardLayout from "@/pages/layouts/DashboardLayout";

let publicationsRoutes = {
  path: '/publications',
  component: DashboardLayout,
  redirect: '/publications/list',
  children: [
    {
      path: 'list/en',

      name: 'publications List',
      component: () => import(/* webpackChunkName: "publications" */  '@/pages/publications/PublicationsList'),
      meta: {
        permissionsCodes: ['en/publications/index', 'restricted_own/en/publications/index'],

      }
    },
    {
      path: 'create/en',
      name: 'Add publication',
      component: () => import(/* webpackChunkName: "publications" */  '@/pages/publications/PublicationsForm'),
      meta: {
        permissionsCodes: ['en/publications/create'],
      }
    },
    {
      path: 'edit/:id/en',
      name: 'Edit publication',
      component: () => import(/* webpackChunkName: "publications" */  '@/pages/publications/PublicationsForm'),
      meta: {
        permissionsCodes: ['en/publications/update', 'restricted_own/en/publications/update'],
      }
    }
  ]
}

export default publicationsRoutes;
