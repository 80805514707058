var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{'nav-open': _vm.$sidebar.showSidebar}},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}}}),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/news/index','restricted_own/en/news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'News', icon: 'nc-icon nc-paper-2', path: '/news/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/projects/index','restricted_own/en/projects/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Projects', icon: 'nc-icon nc-app', path: '/projects/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/publications/index','restricted_own/en/publications/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Publications', icon: 'nc-icon nc-bag', path: '/publications/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/team-members/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Team Members', icon: 'nc-icon nc-vector', path: '/team-members/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['newsletter/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['contact-us/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['en/pages/index', 'restricted_own/en/pages/index',
                                           'en/content-pages/create',
                                           'en/redirection-pages/create',
                                           'en/branches/index', '/restricted_own/en/branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['en/pages/index','restricted_own/en/pages/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Existing Pages', path: '/pages/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/content-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Content Page', path: '/content-pages/create/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/redirection-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Redirection Page', path: '/redirection-pages/create/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/branches/index','restricted_own/en/branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Branches', path: '/branches/list/en'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                     (['en/panel-groups/index','en/panels/index',
                     'restricted_own/en/panel-groups/index','restricted_own/en/panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['en/panels/index','restricted_own/en/panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels', path: '/panels/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/panel-groups/index','restricted_own/en/panel-groups/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Groups', path: '/panel-groups/list/en'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
      (['en/settings/index', 'restricted_own/en/settings/index',
      'en/mediaInfo/index', 'restricted_own/en/mediaInfo/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', icon: 'nc-icon nc-circle-09'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['en/mediaInfo/index', 'restricted_own/en/mediaInfo/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Media Info', path: '/mediaInfo/list/en'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['en/settings/index', 'restricted_own/en/settings/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', path: '/settings/list/en'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/checkAccess']('roles/index')
                          || _vm.$store.getters['auth/checkAccess']('permissions/index')
                          || _vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Access Management', icon: 'nc-icon nc-circle-09'}}},[(_vm.$store.getters['auth/checkAccess']('roles/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Roles', path: '/roles/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('permissions/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Permissions', path: '/permissions/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Users', path: '/users/list'}}}):_vm._e()],1):_vm._e()]}}])},[_c('user-menu'),_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar($event)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }