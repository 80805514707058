<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/news/index','restricted_own/en/news/index'])"
                      :link="{name: 'News', icon: 'nc-icon nc-paper-2', path: '/news/list/en'}"></sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/projects/index','restricted_own/en/projects/index'])"
                      :link="{name: 'Projects', icon: 'nc-icon nc-app', path: '/projects/list/en'}"></sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/publications/index','restricted_own/en/publications/index'])"
                      :link="{name: 'Publications', icon: 'nc-icon nc-bag', path: '/publications/list/en'}"></sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/team-members/index'])"  :link="{name: 'Team Members', icon: 'nc-icon nc-vector', path: '/team-members/list/en'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter/index'])" :link="{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['contact-us/index'])" :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['en/pages/index', 'restricted_own/en/pages/index',
                                             'en/content-pages/create',
                                             'en/redirection-pages/create',
                                             'en/branches/index', '/restricted_own/en/branches/index'])"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/pages/index','restricted_own/en/pages/index'])"
                        :link="{name: 'Existing Pages', path: '/pages/list/en'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/content-pages/create'])"
                        :link="{name: 'Add Content Page', path: '/content-pages/create/en'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/redirection-pages/create'])"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create/en'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/branches/index','restricted_own/en/branches/index'])"
                        :link="{name: 'Branches', path: '/branches/list/en'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['en/panel-groups/index','en/panels/index',
                       'restricted_own/en/panel-groups/index','restricted_own/en/panels/index'])"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/panels/index','restricted_own/en/panels/index'])"
                        :link="{name: 'Panels', path: '/panels/list/en'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/panel-groups/index','restricted_own/en/panel-groups/index'])"
                        :link="{name: 'Groups', path: '/panel-groups/list/en'}"></sidebar-item>

        </sidebar-item>



        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
        (['en/settings/index', 'restricted_own/en/settings/index',
        'en/mediaInfo/index', 'restricted_own/en/mediaInfo/index'])"
            :link="{name: 'Settings', icon: 'nc-icon nc-circle-09'}">

            <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/mediaInfo/index', 'restricted_own/en/mediaInfo/index'])"
                          :link="{name: 'Media Info', path: '/mediaInfo/list/en'}">
            </sidebar-item>
            <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['en/settings/index', 'restricted_own/en/settings/index'])"
                          :link="{name: 'Settings', path: '/settings/list/en'}">
            </sidebar-item>


        </sidebar-item>


        <!--</sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('permissions/index')"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>

        </sidebar-item>





      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
